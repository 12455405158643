<template>
  <component :is="clientData === null ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="warning"
      :show="clientData === null && !loading"
    >
      <!-- <h4 class="alert-heading">
        Erro ao recuperar os dados
      </h4> -->
      <div class="alert-body">
        Não encontramos os dados deste usuário. Voltar para
        <b-link
          class="alert-link"
          :to="{ name: 'clients-list'}"
        >
          a lista de usuários.
        </b-link>
      </div>
    </b-alert>

    <!-- User Info: Input Fields -->
    <b-form
      v-if="clientData && !loading"
    >
      <b-row class="mb-1">
        <b-col>
          <h4>{{ clientData.profile.nome ? clientData.profile.nome : 'Nome do Aluno' }}</h4>
          <span class="timestamp">
            Cadastro : {{ dateFormater.format(new Date(clientData.date_joined)) }}
          </span>
        </b-col>
        <b-col>
          <b-button
            class="float-right"
            variant="success"
            @click="updateClient"
          >
            Gravar
          </b-button>
        </b-col>
      </b-row>

      <b-row>

        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="clientData.profile.nome"
              :state="validation.profile.nome.state"
            />
            <b-form-invalid-feedback>
              {{ validation.profile.nome.feedback }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="clientData.email"
              type="email"
              :state="validation.email.state"
            />
            <b-form-invalid-feedback>
              {{ validation.email.feedback }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Data de nascimento"
            label-for="date"
          >
            <flat-pickr
              id="datepicker-start"
              v-model="clientData.profile.birth_date"
              class="form-control"
              :config="config"
            />
          </b-form-group>
        </b-col>

        <!-- <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="N° Documento (RG/CPF)"
            label-for="doc"
          >
            <b-form-input
              id="doc"
              v-model="clientData.profile.document"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Tipo de documento"
            label-for="tipo_doc"
          >
            <b-form-input
              id="tipo_doc"
              v-model="clientData.profile.document_type"
            />
          </b-form-group>
        </b-col> -->

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Telefone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="clientData.profile.phone"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Segundo Telefone"
            label-for="phone_two"
          >
            <b-form-input
              id="phone_two"
              v-model="clientData.profile.phone_two"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nome do contato de emergência"
            label-for="emerg"
          >
            <b-form-input
              id="emerg"
              v-model="clientData.profile.emergency_contact"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Telefone do contato de emergência"
            label-for="phone_emerg"
          >
            <b-form-input
              id="phone_emerg"
              v-model="clientData.profile.emergency_phone"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mt-1">
        <b-col>
          <h6>Endereço</h6>
        </b-col>
      </b-row>
      <b-row>

        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            label="Logradouro"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="clientData.profile.address"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Número"
            label-for="address_number"
          >
            <b-form-input
              id="address_number"
              v-model="clientData.profile.address_number"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Complemento"
            label-for="address_complement"
          >
            <b-form-input
              id="address_complement"
              v-model="clientData.profile.address_complement"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="CEP"
            label-for="zip_code"
          >
            <b-form-input
              id="zip_code"
              v-model="clientData.profile.zip_code"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            label="Cidade"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="clientData.profile.city"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="UF"
            label-for="state"
          >
            <v-select
              id="state"
              :key="clientData.profile.state"
              v-model="clientData.profile.state"
              :options="$store.state.quales.states"
              :clearable="false"
            >
              <template #no-options="{}">
                A lista de opções está vazia
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Cliente"
            label-for="client"
          >
            <v-select
              id="client"
              :key="clientData.profile.client"
              v-model="clientData.profile.client"
              label="full_name"
              :options="clients"
              :reduce="o => o.id"
              :clearable="false"
            >
              <template #no-options="{}">
                A lista de opções está vazia
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="align-self-end"
        >
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.profile.active"
              name="q11-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Aluno ativo
            </p>
          </div>
        </b-col>
      </b-row>
    </b-form>

    <b-card
      v-if="clientData && !loading"
      class="m-1"
    >
      <h4>Questionário de Prontidão para Atividade Física (PAR-Q)</h4>
      <p>*Este questionário tem objetivo de identificar a necessidade de avaliação por um médico antes do início da atividade física. Caso você responda SIM a uma ou mais perguntas, converse com seu médico ANTES de aumentar seu nível atual de atividade física. Mencione este questionário e as perguntas às quais você respondeu SIM. Por favor, assinale SIM ou NÃO às seguintes perguntas:</p>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.parq.coracao"
              name="coracao"
              switch
            />
            <p
              class="mb-0"
            >
              Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionado por profissionais de saúde?
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.parq.peito"
              name="peito"
              switch
            />
            <p
              class="mb-0"
            >
              Você sente dores no peito quando pratica atividade física?
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.parq.ultimo_mes"
              name="ultimo_mes"
              switch
            />
            <p
              class="mb-0"
            >
              No último mês, você sentiu dores no peito quando pratica atividade física?
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.parq.desequilibrio"
              name="desequilibrio"
              switch
            />
            <p
              class="mb-0"
            >
              Você apresenta desequilíbrio devido a tontura e/ou perda de consciência?
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.parq.osseo"
              name="osseo"
              switch
            />
            <p
              class="mb-0"
            >
              Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física?
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.parq.pressao"
              name="pressao"
              switch
            />
            <p
              class="mb-0"
            >
              Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração?
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.parq.nao_deve"
              name="nao_deve"
              switch
            />
            <p
              class="mb-0"
            >
              Sabe de alguma outra razão pela qual você não deve praticar atividade física?
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="termos">Termos</label>
          <b-form-textarea
            id="termos"
            v-model="termos"
            rows="5"
            max-rows="5"
            no-auto-shrink
            disabled
          />
          <div class="d-flex my-1">
            <b-form-checkbox
              v-model="clientData.parq.termo_responsabilidades"
              name="termo_responsabilidades"
              switch
            />
            <p
              class="mb-0"
            >
              Li e concordo com o Termo
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            class="float-right"
            variant="success"
            @click="updateClient"
          >
            Gravar
          </b-button>
        </b-col>
      </b-row>
    </b-card>

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BFormGroup, BFormInput, BMedia, BButton,
  BRow, BCol, BForm, BAvatar, BFormTextarea, BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BMedia,
    BButton,
    BRow,
    BCol,
    BForm,
    BAvatar,
    BFormTextarea,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    flatPickr,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      dateFormater: new Intl.DateTimeFormat(),
      termos: 'Declaro para os devidos fins, estar em plenas condições de saúde e estar autorizado por meu médico a praticar atividades físicas. Declaro ainda, isentar totalmente qualquer professor, monitor ou estagiário da empresa QUALES Atividades Esportivas LTDA., de quaisquer responsabilidades decorrentes de acidentes durante as atividades, exceto aqueles causados comprovadamente por omissão, imprudência ou imperícia do profissional responsável. Por fim, registro neste ato o compromisso de apresentar um atestado médico para prática esportiva em no máximo 10 dias úteis e renová-lo a cada 6 meses.',
      validation: {
        email: {
          value: null,
          feedback: '',
        },
        profile: {
          nome: {
            value: null,
            feedback: '',
          },
        },
      },
      validationBase: {
        email: {
          value: null,
          feedback: '',
        },
        profile: {
          nome: {
            value: null,
            feedback: '',
          },
        },
      },
      clientData: null,
      loading: true,
      emptyProfile: {
        nome: '',
        document: '',
        document_type: '',
        birth_date: null,
        phone: '',
        phone_two: '',
        emergency_phone: '',
        emergency_contact: '',
        zip_code: '',
        address: '',
        address_number: '',
        address_complement: '',
        city: '',
        state: 'SP',
        active: true,
        client: '',
      },
      emptyPARQ: {
        coracao: false,
        peito: false,
        ultimo_mes: false,
        desequilibrio: false,
        osseo: false,
        pressao: false,
        nao_deve: false,
        termo_responsabilidades: false,
      },
      emptyClient: {
        email: '',
      },
      config: {
        allowInput: false,
        locale: Portuguese,
      },
    }
  },
  computed: {
    clients() {
      return this.$store.state.quales.clients
    },
  },
  beforeCreate() {
    this.$store.dispatch('quales/getClients')
  },
  created() {
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      // hour: 'numeric',
      // minute: '2-digit',
      // weekday: 'long',
      timeZone: 'America/Sao_Paulo',
    })
    if (this.userId !== -1) {
      this.$store.dispatch('students/getClientById', this.userId).then(() => {
        this.clientData = this.$store.state.students.clientData
        if (this.clientData.profile === null) {
          this.clientData.profile = JSON.parse(JSON.stringify(this.emptyProfile))
        }
        if (this.clientData.parq === null) {
          this.clientData.parq = { ...this.emptyPARQ }
        }
      })
    } else {
      this.clientData = JSON.parse('{"email":"","profile":{"nome":"","document":"","document_type":"","birth_date":null,"phone":"","phone_two":"","emergency_phone":"","emergency_contact":"","zip_code":"","address":"","address_number":"","address_complement":"","city":"","state":null,"active":true,"client": null},"parq":{"coracao":false,"peito":false,"ultimo_mes":false,"desequilibrio":false,"osseo":false,"pressao":false,"nao_deve":false,"termo_responsabilidades":false}}')
    }
    if (this.clientData && !this.clientData.profile.client) {
      if (!this.clients[0]) {
        this.$store.dispatch('quales/getClients').then(() => {
          setTimeout(() => {
            this.clientData.profile.client = this.clients[0].id
            this.loading = false
          },
          500)
        })
      } else {
        this.clientData.profile.client = this.clients[0].id
        this.loading = false
      }
    } else {
      this.loading = false
    }
  },
  methods: {
    updateClient() {
      if (this.clientData.profile.birth_date === '') {
        this.clientData.profile.birth_date = null
      }
      this.$store.dispatch('students/updateClient', this.clientData)
        .then(response => {
          this.validation = JSON.parse(JSON.stringify(this.validationBase))
          this.clientData.id = response.data.id
          this.updated = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aluno',
              icon: 'UserCheckIcon',
              variant: 'success',
              text: response.status === 200 ? 'Aluno atualizado' : 'Aluno criado',
            },
          })
          this.$router.push({ name: 'students-list' })
        })
        .catch(error => {
          this.validation = JSON.parse(JSON.stringify(this.validationBase))
          /* eslint-disable */
          for (const [k, v] of Object.entries(error.response.data)) {
            if (Array.isArray(v)) {
              this.validation[k] = {
                state: false,
                feedback: v[0],
              }
            } else {
              for (const [k2, v2] of Object.entries(v)) {
                this.validation[k][k2] = {
                  state: false,
                  feedback: v2[0],
                }
              }
            }
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aluno',
              icon: 'UserXIcon',
              variant: 'danger',
              text: 'Não conseguimos gravar esses dados. Por favor verificar.',
            },
          })
        })
    },
  },
  setup() {

    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  @import "~flatpickr/dist/themes/airbnb.css";
.timestamp {
  font-size: 12px;
  font-weight: 900;
  // font-style: italic;
  color: #006db5d5;
}
.form-control[readonly] {
  background-color: inherit;
}
</style>
